import React from "react";
import { Container, Grid, Card, CardContent, Typography, Button, CardActions, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';


const StyledCard = styled(Card)(({ theme }) => ({
  minHeight: 200,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxShadow: theme.shadows[5],
  transition: 'transform 0.3s',
  '&:hover': {
    transform: 'scale(1.05)',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundImage: 'linear-gradient(to right, #d96704, #e88c2a)',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundImage: 'linear-gradient(to right, #e88c2a, #d96704)',
  },
}));


const Home = () => {
  const user = useSelector((state) => state.user.user);
  const apiToken = useSelector((state) => state.user.apiToken);
  return (
    <Container maxWidth="lg">
       <Helmet>
        <title>Home</title>
        <meta name="description" content="Tuition Reimbursment" />
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
        }}
      >
        { user !== null && apiToken &&
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} sm={12} md={12}>
            <StyledCard>
            <CardContent>
                <Typography variant="h5" component="div">
                Tuition Reimbursement Program
                </Typography>
                <br></br>
                <Typography variant="body1" color="textSecondary">
                This portal is used for pre-approval to participate in the Pharmavite's Tuition Reimbursement program. Eligible programs include both on campus and online accredited colleges or Trade Schools for undergraduate, graduate, and post-graduate degrees applicable to your current position duties and earned from accredited institutions recognized by both the United States Department of Education (USDE) and the Council for Higher Education Accreditation (CHEA). For a list of regional accrediting commissions and accredited institutions go to <a href="https://www.chea.org/search-institutions">https://www.chea.org/search-institutions</a>.  
                </Typography>
                <br></br>
                <Typography variant="body1" color="textSecondary">
                It is important that you review the Employee Handbook for eligible participation and guidelines. Only full-time employees who have been employed with Pharmavite for at least 90 days are eligible to participate. The maximum reimbursement amount is $5,250 annually and you are required to meet minimum grade standards as specified in the program guidelines and policies found in the <a href="https://vitalityhub.pharmavite.com/api/media/secure/external/v2/raw/upload/66ba3be323c8c768a95de4ce.pdf">Employee Handbook</a>.
                </Typography>
                <br></br>
                <Typography variant="body1" color="textSecondary">
                If you are planning to participate in the University of Arizona Global Campus (UAGC), please do not use this portal and access the <a href="http://success.uagc.edu/pharmavite">UAGC portal here</a>. 
                </Typography>
                <br></br>
                <Typography variant="body1" color="textSecondary">
                If you are pre-approved to attend a program outside of UAGC, you may enroll in courses. Any submissions for reimbursement will require you to submit receipts for approved expenses (tuition, books, lab fees, parking fees, etc.) and completed course transcripts through Pharmavite's expense system - Concur. 
                </Typography>
                <br></br>
                <Typography variant="body1" color="textSecondary">
                To proceed with submitting a pre-approval request for tuition reimbursement, please select the appropriate tile below.
                </Typography>
              </CardContent>
              <CardActions>
                  <StyledButton href="/How_To_Submit_Tuition_Reimbursement_Report_in_Concur.pdf" download variant="contained" fullWidth>
                    Click to Download Tuition Reimbursement How-To Directions
                  </StyledButton>
                </CardActions>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h5" component="div">
                  New Pre-Approval Request
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Submit a new tuition reimbursement pre-approval request for yourself.
                </Typography>
              </CardContent>
              <CardActions>
                <StyledButton component={Link} to="/new-request" variant="contained" fullWidth>
                  Start
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h5" component="div">
                  New Pre-Approval Request For Someone Else
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Submit a new tuition reimbursement pre-approval request on behalf of someone else.
                </Typography>
              </CardContent>
              <CardActions>
                <StyledButton component={Link} to="/user-search" variant="contained" fullWidth>
                  Start
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <StyledCard>
              <CardContent>
                <Typography variant="h5" component="div">
                  My Previous Pre-Approval Requests
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  View and manage your previous tuition reimbursement pre-approval requests.
                </Typography>
              </CardContent>
              <CardActions>
                <StyledButton component={Link} to="/previous-requests" variant="contained" fullWidth>
                  View
                </StyledButton>
              </CardActions>
            </StyledCard>
          </Grid>
        </Grid>
        }
      </Box>
    </Container>
  );
};

export default Home;
